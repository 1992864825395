<template>
  <vca-card>
    <h2>{{ $t("organisation.title") }}</h2>
    <vca-card class="shadowed vca-center">
      <OrganisationTable />
      <vca-popup
        v-if="current != null"
        :show="current != null"
        :title="$t('organisation.popup.edit', { 0: current.display_name })"
        @close="close()"
      >
        <OrganisationForm v-model="current" @submit="update()" />
        <div class="vca-center">
          <vca-cancel-button
            @click="deleteArtist()"
            :placeholder="$t('button.delete')"
          />
        </div>
      </vca-popup>
      <vca-popup
        v-if="doCreate"
        :show="doCreate"
        :title="$t('organisation.popup.add')"
        @close="doCreate = false"
      >
        <OrganisationForm v-model="create" @submit="add()" />
      </vca-popup>
    </vca-card>
  </vca-card>
</template>

<script>
import OrganisationTable from "@/components/organisations/OrganisationTable";
import { mapGetters } from "vuex";

import OrganisationForm from "@/components/organisations/OrganisationForm.vue";
export default {
  name: "OrganisationView",
  components: { OrganisationTable, OrganisationForm },
  data() {
    return {
      initialized: false,
    };
  },
  created() {
    this.$store.dispatch({ type: "organisations/list" });
    console.log("FIND ORGANISATIONS");
  },
  computed: {
    ...mapGetters({
      user: "user/current",
    }),
    current: {
      set(value) {
        this.$store.state.organisations.current = value;
      },
      get() {
        return this.$store.state.organisations.current;
      },
    },
    create: {
      set(value) {
        this.$store.state.organisations.create = value;
      },
      get() {
        return this.$store.state.organisations.create;
      },
    },
    doCreate: {
      set(value) {
        this.$store.state.organisations.doCreate = value;
      },
      get() {
        return this.$store.state.organisations.doCreate;
      },
    },
  },
  methods: {
    add() {
      if (!confirm(this.$t("organisation.messages.add"))) {
        return false;
      }
      this.$store.dispatch({ type: "organisations/create" }).then(() => {
        this.create = { name: "" };
      });
    },
    update() {
      if (!confirm(this.$t("organisation.messages.update"))) {
        return false;
      }
      this.$store.dispatch({ type: "organisations/update" });
    },
    deleteArtist() {
      if (
        confirm(
          this.$t("organisation.edit.delete.confirm", {
            0: this.current.name,
          })
        )
      ) {
        this.$store.dispatch({
          type: "organisations/delete",
          data: this.current.id,
        });
      }
    },
    close() {
      this.$store.commit("organisations/clean_current");
    },
  },
};
</script>
